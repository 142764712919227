import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { pathname } from "./pathname";
import PrivateOutlet from "./privateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicy from "./Pages/BeforeAuth/PrivacyPolicy";
import TermsAndConditions from "./Pages/BeforeAuth/TermsAndConditions";
import CoporateOnboarding from "./Pages/AfterAuth/CoporateOnboarding";
// import SessionExpire from "./Pages/BeforeAuth/SessionExpire.jsx";
import ProfilePage from "./Pages/AfterAuth/ProfilePage.jsx";
// import BulkNotification from "./Pages/AfterAuth/BulkNotification";
const IncentivePayouts = React.lazy(() =>
  import("./Pages/AfterAuth/IncentivePayouts")
);
const Dashboard = React.lazy(() => import("./Pages/AfterAuth/Dashboard"));
const Login = React.lazy(() => import("./Pages/BeforeAuth/Login"));
const ForgotPassword = React.lazy(() =>
  import("./Pages/BeforeAuth/ForgotPassword")
);
const ChangePassword = React.lazy(() =>
  import("./Pages/BeforeAuth/ChangePassword")
);
const CheckEmail = React.lazy(() => import("./Pages/BeforeAuth/CheckEmail"));
const MobilizerManagement = React.lazy(() =>
  import("./Pages/AfterAuth/MobilizerManagement")
);
const CenterManagement = React.lazy(() =>
  import("./Pages/AfterAuth/CenterManagement")
);
const SessionExpireRedirect = React.lazy(() =>
  import("./Pages/BeforeAuth/SessionExpire.jsx")
);
const JobManagement = React.lazy(() =>
  import("./Pages/AfterAuth/JobManagement")
);
const AddAndEditJob = React.lazy(() =>
  import("./Components/JobManagement/AddAndEditJob")
);
const JobCategory = React.lazy(() => import("./Pages/AfterAuth/JobCategory"));
const AddandEditJobCategory = React.lazy(() =>
  import("./Components/JobCategory/AddandEditJobCategory")
);
const CompanyCategory = React.lazy(() =>
  import("./Pages/AfterAuth/CompanyCategory")
);
const AddCompany = React.lazy(() =>
  import("./Components/CompanyCategory/AddCompany")
);
const ReferralManagement = React.lazy(() =>
  import("./Pages/AfterAuth/ReferralManagement")
);
const ReferralStatusPage = React.lazy(() =>
  import("./Components/ReferralManagement/ReferralStatusPage")
);
const QuerryManagement = React.lazy(() =>
  import("./Pages/AfterAuth/QuerryManagement")
);

const SubAdmins = React.lazy(() => import("./Pages/AfterAuth/SubAdmins"));
const EditSubAdmins = React.lazy(() =>
  import("./Components/setting/subAdmin/AddAdmin.jsx")
);

const Managepermission = React.lazy(() =>
  import("./Components/setting/subAdmin/ManagePermission.jsx")
);
const TrainingAndFaq = React.lazy(() =>
  import("./Pages/AfterAuth/TrainingAndFaq")
);
const BulkNotification = React.lazy(() =>
  import("./Pages/AfterAuth/BulkNotification")
);
const AddFaqs = React.lazy(() => import("./Components/Faqs/AddFAQs.js"));
const RefferedCandidates = React.lazy(() =>
  import("./Components/Mobilizers/RefferedCandidates.js")
);
const EmailVerify = React.lazy(() =>
  import("./Pages/BeforeAuth/AppEmailVerify")
);

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Suspense fallback={<></>}>
        <Router>
          <Routes>
            <Route
              path={pathname.session_expire}
              element={<SessionExpireRedirect />}
            />
            <Route exact path={pathname.LOGIN} element={<Login />} />
            {/* <Route
              exact
              path={pathname.CORPORATE_ONBOARDING}
              element={<Onboard />}
            /> */}

            <Route
              path={pathname.FORGOT_PASSWORD}
              element={<ForgotPassword />}
            />
            <Route
              path={pathname.CHANGE_PASSWORD}
              element={<ChangePassword />}
            />
            <Route path={pathname.CHECK_EMAIL} element={<CheckEmail />} />
            <Route path={pathname.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route
              path={pathname.TERMS_AND_CONDITION}
              element={<TermsAndConditions />}
            />
            <Route path={pathname.APP_EMAIL_VERIFY} element={<EmailVerify />} />

            <Route path={pathname.DASHBOARD} element={<PrivateOutlet />}>
              <Route index element={<Dashboard />} />
              <Route
                path={pathname.MOBILIZER_MANAGEMENT}
                element={<MobilizerManagement />}
              />
              <Route
                path={pathname.CENTER_MANAGEMENT}
                element={<CenterManagement />}
              />
              <Route
                path={pathname.JOB_MANAGEMENT}
                element={<JobManagement />}
              />

              <Route
                path={pathname.BULK_NOTIFICATION}
                element={<BulkNotification />}
              />
              <Route
                path={pathname.JOB_MANAGEMENT_ADD}
                element={<AddAndEditJob />}
              />
              <Route
                path={pathname.JOB_MANAGEMENT_EDIT}
                element={<AddAndEditJob />}
              />
              <Route path={pathname.JOB_CATEGORY} element={<JobCategory />} />
              <Route
                path={pathname.ADD_JOB_CATEGORY}
                element={<AddandEditJobCategory />}
              />
              <Route
                path={pathname.EdIT_JOB_CATEGORY}
                element={<AddandEditJobCategory />}
              />

              <Route
                path={pathname.COMPANY_CATEGORY}
                element={<CompanyCategory />}
              />
              <Route
                path={pathname.ADD_COMPANY_CATEGORY}
                element={<AddCompany />}
              />
              <Route
                path={pathname.REFERRAL_MANAGEMENT}
                element={<ReferralManagement />}
              />
              <Route
                path={pathname.REFERRAL_MANAGEMENT_STATUS}
                element={<ReferralStatusPage />}
              />
              <Route
                path={pathname.QUERRY_MANAGEMENT}
                element={<QuerryManagement />}
              />
              <Route
                path={pathname.COPORATE_OBOARDING}
                element={<CoporateOnboarding />}
              />
              <Route
                path={pathname.INCENTIVE_PAYOUTS}
                element={<IncentivePayouts />}
              />
              <Route path={pathname.SUB_ADMINS} element={<SubAdmins />} />
              <Route path={pathname.SUB_ADMINS} element={<EditSubAdmins />} />

              <Route
                path={pathname.MANAGE_PERMISSION}
                element={<Managepermission />}
              />
              <Route
                path={pathname.TRAINING_AND_FAQ}
                element={<TrainingAndFaq />}
              />
              <Route path={pathname.ADD_FAQ} element={<AddFaqs />} />
              <Route path={pathname.EDIT_FAQ} element={<AddFaqs />} />
              <Route
                path={pathname.Reffered_Candidates}
                element={<RefferedCandidates />}
              />
              <Route
                path={pathname.Mobilizers_person_details}
                element={<ReferralStatusPage />}
              />
              <Route path={pathname.PROFILE} element={<ProfilePage />} />{" "}
              {/* add profile path */}
            </Route>
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;