import React, { useEffect } from "react";
import Header from "./Components/Common/Header";
import SiderBar from "./Components/Common/SiderBar";
import { Navigate, Outlet } from "react-router-dom";
import { pathname } from "./pathname";
import { fetchToken,fetchSessionState } from "./Assests/CommonFunction";
import { useDispatch, useSelector } from "react-redux";
import { getRoleAccess } from "./Api/rolaCheck";
import {
  setPermissions,
  setRoleAccess,
} from "./redux/Slices/RBAC/roleBasedActionSlice";
import SessionExpire from "./Pages/BeforeAuth/SessionExpire";

export const PrivateOutlet = () => {
  const token = fetchToken();
  const session_state=fetchSessionState();

  const dispatch = useDispatch();
  const isPermissionset = useSelector(
    (state) => state?.RoleBasedActions?.isPermissionSet
  );
  const isSideBarOpen = useSelector(
    (state) => state.SidebarVariables.isSideBarOpen
  );

  const manageRoleAccess = async () => {
    try {
      const response = await getRoleAccess();
      if (response) {
        dispatch(setRoleAccess(response));
        dispatch(setPermissions(response));
      }
    } catch (error) {}
  };

  useEffect(() => {
    manageRoleAccess();
  }, []);

  return token ? (
    <>
      <div className="flex w-full h-full overflow-hidden">
        <div
          className={`${
            isSideBarOpen ? "w-[20%]" : "w-[4rem]"
          } h-[100vh] overflow-hidden`}
        >
          <SiderBar />
        </div>
        <div className={`w-full h-full overflow-x-hidden`}>
          <Header />
          {isPermissionset && <Outlet />}
        </div>
      </div>
    </>
  ) : session_state?<><SessionExpire/></>:(
    <Navigate to={pathname.LOGIN} />
  );
};

export default PrivateOutlet;
