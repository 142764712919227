export const pathname = {
  DASHBOARD: "/",
  BULK_NOTIFICATION:"bulk-notification",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forget-password",
  CHANGE_PASSWORD: `/reset-password/:token`,
  CHECK_EMAIL: "/check-email",
  MOBILIZER_MANAGEMENT: "/mobilizer-management",
  CENTER_MANAGEMENT: "/center-management",
  JOB_MANAGEMENT: "/job-management",
  JOB_MANAGEMENT_ADD: "/job-management/add",
  JOB_MANAGEMENT_EDIT: "/job-management/edit/:id",
  JOB_CATEGORY: "/category-job",
  ADD_JOB_CATEGORY: "/category-job/add",
  EdIT_JOB_CATEGORY: "/category-job/edit/:id",
  COMPANY_CATEGORY: "/category-company",
  ADD_COMPANY_CATEGORY: "/category-add-company",
  REFERRAL_MANAGEMENT: "/referral-management",
  REFERRAL_MANAGEMENT_STATUS: "/referral-management/status/:id",
  QUERRY_MANAGEMENT: "/querry-management",
  INCENTIVE_PAYOUTS: "/incentive-payouts",
  SUB_ADMINS: "/settings-sub-admins",
  SUB_ADMINS_EDIT: "/settings-sub-admins/:id",
  MANAGE_PERMISSION: "/settings-manage-permissions",
  TRAINING_AND_FAQ: "/training-and-faq",
  session_expire: "/session-expire",
  ADD_FAQ: "/training-and-faq/add-faq",
  EDIT_FAQ: "/training-and-faq/edit-faq/:id",
  Reffered_Candidates: "/mobilizer-management/reffered-candidates",
  Mobilizers_person_details:
    "/mobilizer-management/reffered-candidates/mobilizers-detail/:id",
  PRIVACY_POLICY: "privacy-policy",
  TERMS_AND_CONDITION: "terms-and-conditions",
  APP_EMAIL_VERIFY: "/email-verify",
  CORPORATE_ONBOARDING: "/corporate-onboarding/verify-link",
  PROFILE: "/profile"
};

// Don't add path in between, add it at the last only
