import { useNavigate } from "react-router-dom";
import { Button } from "antd";



export default function SessionExpire() {

  const navigate = useNavigate();

  return (
    <>
      <div className="flex bg-[#1A80FF] overflow-hidden min-h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md"></div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-10 shadow sm:rounded-lg sm:px-12 flex flex-col justify-between items-center">
            <h3 className=" text-center text-2xl mb-8 font-bold leading-9 tracking-tight text-gray-900">
              Session Expired
            </h3>
            <Button className=" text-center  bg-blue text-white mb-5 cursor-pointer" onClick={()=>{
                localStorage.clear()
                navigate("/login")
            }}> Kindly Sign-In Again</Button>
          </div>
        </div>
      </div>
    </>
  );
}
