import React, { useEffect, useRef } from "react";
import { Card, Form, Input, Select, Button, Avatar, Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import leftArrow from "../../Assests/Images/leftArrow.svg";

const { Option } = Select;

const ProfilePage = () => {
  const uploadRef = useRef(null);
  const [form] = Form.useForm();

  const storedUserData = JSON.parse(localStorage.getItem("roleInfo"));

  if (storedUserData) {
    form.setFieldsValue({
      firstName: storedUserData.first_name,
      lastName: storedUserData.last_name,
      email: storedUserData.email,
      designation: storedUserData.name,
    });
  }

  return (
    <div className="overflow-y-auto h-[90vh] bg-lightgrey block space-y-5 p-4">
      <div className="flex items-center font-sans text-[1.25rem] font-semibold mb-4">
        <img src={leftArrow} alt="back" />
        My Profile
      </div>

      <div className="flex justify-center">
        <Card className="w-full max-w-4xl items-center p-6">
          <h2 className="text-xl font-semibold mb-6">Personal Details</h2>

          <Form layout="vertical" form={form}>
            <Form.Item>
              <Avatar
                size={120}
                icon={<UserOutlined />}
                style={{ cursor: "pointer" }}
              />
              <input
                type="file"
                ref={uploadRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={(info) => {
                  console.log(info.target.files[0]);
                }}
              />
            </Form.Item>

            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item label="First Name" name="firstName" required>
                  <Input placeholder="Enter your first name" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item label="Last Name" name="lastName" required>
                  <Input placeholder="Enter your last name" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item label="Email" name="email">
                  <Input type="email" placeholder="Enter your email address" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="Designation" name="designation" required>
                  <Input placeholder="Enter your designation" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="Phone Number" name="phone">
                  <Input
                    addonBefore="+91"
                    placeholder="Enter your phone number"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item label="Gender" name="gender">
                  <Select placeholder="Select gender">
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                    <Option value="other">Other</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <div className="flex justify-end">
                <Button
                  htmlType="submit"
                  className="w-[180px] bg-[#F7553D] text-white"
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ProfilePage;
