import React, { useState, useRef, useEffect } from "react";
import { UserOutlined, PoweroffOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { pathname } from "../../../src/pathname";
import logoutIcon from "../../Assests/Images/logout.svg";

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const roleInfo = JSON.parse(localStorage.getItem("roleInfo"));
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate(pathname.LOGIN);
  };

  const handleProfile = () => {
    navigate(pathname.PROFILE);
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full h-[10vh] flex items-center justify-between border-b-2">
      <div className="w-full h-full flex justify-between items-center px-6">
        <div>
          <p className="font-semibold">Hi {roleInfo?.first_name}!</p>
          <p className="text-grey">Let’s check your Kadam today.</p>
        </div>
        <div
          className="flex justify-between items-center gap-3 relative"
          ref={dropdownRef}
        >
        
          <div className="flex items-center gap-2 cursor-pointer" onClick={toggleDropdown}>
            <div
              className="p-1 border-2 rounded-full h-10 w-10 flex items-center justify-center border-black"
            >
              <UserOutlined />
            </div>
            <div>
              <p className="font-medium">{`${roleInfo?.first_name} ${roleInfo?.last_name}`}</p>
              <p className="text-grey text-[0.8rem] capitalize">
                {roleInfo?.name}
              </p>
            </div>
          </div>
        
          {isDropdownOpen && (
            <div
              className="absolute right-0 w-48 bg-white border rounded shadow-lg z-10"
              style={{ top: "calc(100% + 10px)" }}
            >
              <div
                className="flex items-center py-2 px-4 hover:bg-gray-100 cursor-pointer"
                onClick={handleProfile}
              >
                <UserOutlined className="mr-2" />
                <span>Profile</span>
              </div>
              <div
                className="flex items-center py-2 px-4 hover:bg-gray-100 cursor-pointer"
                onClick={handleLogout}
              >
                <img src={logoutIcon} alt="logout" />
                <span>Logout</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
