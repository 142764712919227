import { createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../axios";
import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { exportToCSV } from "../../../Assests/CommonFunction";
export const downloadReferralCSV = createAsyncThunk(
  "downloadReferralCSV",
  async (args, { getState, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/referrals/admin/export/job-count`
      );
      if (response?.status === 200) {
        const data = response?.data;
        exportToCSV(data?.data, "Referral Job Count CSV");
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      // throw error;
    }
  }
);
export const downloadReferralTemplateCSV = createAsyncThunk(
  "downloadReferralTemplateCSV",
  async (args, { getState, rejectWithValue }) => {
    try {
      const response =
        await axiosInstance.post(`/referrals/admin/download/csvtemplate?download_csv=true
 `);
      if (response?.status === 200) {
        const data = response;
        exportToCSV(data?.data, "Referral bulk upload template csv");
        toast.success("Bulk upload referral template csv downloaded");
      }
    } catch (error) {
      toast.error("Error in downloading template csv");
      // toast.error(error?.response?.data?.message);
      // throw error;
    }
  }
);
const initialState = {
  referralFilters: {},
};

const referralSlice = createSlice({
  name: "referralVarSlice",
  initialState,
  reducers: {
    setReferralFilters: (state, action) => {
      state.referralFilters = action.payload;
    },
    removeSelectedCategory: (state, action) => {
      if (state.referralFilters.category_id) {
        state.referralFilters.category_id = undefined;
      }
    },
    removeSelectedMobilizer: (state, action) => {
      if (state.referralFilters.mobilizer_id) {
        state.referralFilters.mobilizer_id = undefined;
      }
    },
    removeSelectedStage: (state, action) => {
      if (state.referralFilters.stage_id) {
        state.referralFilters.stage_id = undefined;
      }
    },
    removeSelectedCity: (state, action) => {
      if (state.referralFilters.city_id?.length > 0) {
        state.referralFilters.city_id = state.referralFilters.city_id.filter(
          (item) => item !== action.payload
        );
      }
    },
    removeSelectedCompany: (state, action) => {
      if (state.referralFilters.company_id?.length > 0) {
        state.referralFilters.company_id =
          state.referralFilters.company_id.filter(
            (item) => item !== action.payload
          );
      }
    },
    resetJobFilter: (state) => {
      state.referralFilters = initialState.referralFilters;
    },
  },
});

export const {
  setReferralFilters,
  removeSelectedCategory,
  removeSelectedStage,
  removeSelectedCity,
  removeSelectedCompany,
  removeSelectedMobilizer,
  resetJobFilter,
} = referralSlice.actions;

export default referralSlice.reducer;
